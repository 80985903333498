import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../Login/LoGin.vue'
import Layout from '../Layout/LaYout.vue'
import first from '../kind/FrIst.vue'
import secend from '../kind/SecEnd.vue'
import three from '../kind/ThRee.vue'
import goods from '../kind/GooDs.vue'
import addgoods from'../kind/ADDGooDs.vue'
import enditgoods from '../kind/ENDITGooDs.vue'
Vue.use(VueRouter)
const routes = [
  {
    path:'', 
    name:'Login',
    component:Login,
  },
 {
  path:'/Layout',
  component:Layout,
  children:[
  
    {
      path:'/first',
      name:'first',
      component:first
    },
    
    {
      path:'/secend',
      name:'secend',
      component:secend
    },
    {
      path:'/three',
      name:'three',
      component:three,
      
    },
    {
      path:'/goods',
      name:'goods',
      component:goods,
      
    },
    {path:'/addgoods',
      name:'addgoods',
      component:addgoods},
    {path:'/enditgoods',
      name:'enditgoods',
      component:enditgoods}
  ]
  
 }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})



export default router
