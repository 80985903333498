<template>
    <div id="app">
      <h2>登录页面</h2>
      <form @submit.prevent="handleLogin">
        <label for="username">用户名：</label>
        <input type="text" id="username" v-model="username" placeholder="请输入用户名" />
        <br />
        <label for="password">密码：</label>
        <input type="password" id="password" v-model="password" placeholder="请输入密码" />
        <br />
        <button type="submit" >登录</button>
      </form>
      <div v-if="errorMessage">{{ errorMessage }}</div>
    </div>
    

  </template>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        errorMessage: ''
      };
    },
    methods: {
      open() {
        this.$alert('账号或密码错误', {
          confirmButtonText: '确定',
          
        });
      },

    handleLogin(res){
        // 这里简单验证用户名和密码，实际中可能会发送请求到后端验证
        console.log(res)
        var username=res.target[0]._value
        var password=res.target[1]._value
        console.log("usr",username)
        console.log("pas",password)


        this.$api.gettoken({"username":username,"password":password}).then(res=>{
        console.log(res.data.data)
        localStorage.setItem('token',res.data.data);
        if(res.status==200){
          this.$router.push({name:'first'})


        }
      }).catch(error =>{
        if(error.response) {
          
          // 请求成功，但服务器响应错误
          this.open();
        } else if(error.request) {
          // 请求已发送但没有收到响应
          console.log(error.request);
        } else
     {
          // 其他错误
    console.log('Error', error.message);
        }


      })
    }




   
        //数据
    }
  };
  </script>
  
  <style>
  body {
    font-family: Arial, sans-serif;
  }
  form {
    width: 400px;
    margin: 180px auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  button {
    margin:  auto;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  button:hover {
    background-color: #0056b3;
  }
  </style>