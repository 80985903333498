<template>
  <div class="layout">
    <MyMenu class="memu" :isCollapse="isCollapse"/>
    <ConTent class="content" :class="{isActive:isCollapse}" @change='change' :isCollapse="isCollapse"/>
  </div>
</template> 

<script>
import MyMenu from './MyMenu.vue'
import ConTent from './ConTent.vue';  
export default {
  components:{
    MyMenu,
    ConTent
  }, 
  data(){
    return{
      isCollapse:false,
    }  
  },
  methods:{
    change(){
      this.isCollapse=!this.isCollapse
    }

  }
}
 
</script>

<style lang='less' scoped>
.layout{
  .memu{
    min-height: 500px;
    background: #666;
    position: fixed;
    top: 0;
    bottom: 0;
  }
  .content{
    margin-left: 200px;
}
  .isActive{
    margin-left: 64px;
  }
}

</style>
