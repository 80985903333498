
<template>
  <div>
    <el-upload
    class="upload-demo"
    ref="upload" 
    :action="url"
    :headers="{Token:token}"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :on-success="successUpload"
    :file-list="fileList"
    :auto-upload="false"
    :limit='img'>
      <el-button slot="trigger" size="small" type="primary" @click="submitImg">选取文件</el-button>
    </el-upload>
    </div>
</template>

<script>
import base from '../api/base';

export default {
  created:function (){   
    this.token=localStorage.getItem('token')
    },
  props:{
  //   contents: String,
      limit: {
          type: Number, // 指定属性类型为数值类型
          required: true // 可以设置为必需属性
     },
     addnxsimg:[],
     parentId:{
      type:String,
      required:false
     }
   },
  data() {
      return {
        autoUpload:true,
        img:1,
        dedaoimg:'',
        url:base.Upload,
        token:'',
        fileList:[],
        xsimg :'',
        nxsimg:[],
        add:[]
      };
    },
    methods: {
      // 上传事件
      submitUpload() {
        this.$refs.upload.submit();
      },
      // 判断图片数
      submitImg(){
        if(this.limit==1){
          this.img=1
          if(this.file==1){
            this.$message({
          message: '只能上传一张图片',
          type: 'success'
        });
          }
        }else if(this.limit==2){
          this.img=20
          if(this.file==20){
            this.$message({
          message: '只能上传20张图片',
          type: 'success'
        });
          }
        }
      },
      // 上传成功
      successUpload(response,file,fileList){
        this.file=fileList.length
        var dedaoimg = response.data
        this.dedaoimg=response.data
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        let filePath=response.data
        this.$api.downfile({
          filePath
        }).then(res=>{
        var xsimg = res.data.data
        this.xsimg=res.data.data
        this.nxsimg=this.nxsimg.concat(res)
        console.log("nxsimg",this.nxsimg)
        var nxsimg=this.nxsimg
        this.$emit("sendImg",dedaoimg,xsimg,nxsimg)
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else{
      // 其他错误
console.log('Error', error.message);
    }
  });if(this.limit==2){
    
    this.$api.addimg({
            "lampId":this.parentId,
            "pictureUrl":this.dedaoimg
        }).then(res=>{
          console.log(res) 
    const addimg={id:res.data.data,pictureUrl:this.xsimg}
    this.add.push(addimg)
    this.$emit("sendImgid",addimg)
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else{
      // 其他错误
console.log('Error', error.message);
    }
  });
  }
  
      },
      // 清除列表
      clearFiles(){
        this.$refs.upload.clearFiles();
        console.log("清理成功")
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      }
    },
   
}

</script>

<style lang='less' scoped>

</style>
