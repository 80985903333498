<template>
  <div>
    <el-menu
      default-active="/first"
      class="el-menu-vertical-demo"     
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
      :collapse="isCollapse"
      >
      <el-menu-item>
        <span slot="title">灯具管理后台系统</span>
      </el-menu-item>
      <el-menu-item index="/first">
        <i class="el-icon-menu"></i>
        <span slot="title">一级分类</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script> 
export default {
  props:['isCollapse']
  
}

</script>

<style lang='less' scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }


</style>
