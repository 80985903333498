<template>
  <div class="goods">
    <!-- 搜索 -->
    <div class="title">
      <el-page-header @back="goBack" content="二级分类">
      </el-page-header>
    </div>
    <div>
      <el-input @change="searchInp" style="width: 800px;" v-model="input" placeholder="请输入内容"></el-input>
      <el-button @click="search" type="primary"  >搜索</el-button>
      <el-button @click="reset" type="primary"  >重置</el-button>
      <el-button type="primary" @click="add" >新建</el-button>
      <div class="header">
      <el-upload 
      :action="imurl" 
      :data="imdata"
      :headers="{Token:token}"
      :on-success="imsuccess"
      :on-error="imerror"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      multiple
      :on-exceed="handleExceed"
      
      >
  <el-button  type="primary">导入</el-button>
</el-upload>
<el-button  type="primary" @click="exportdata" style="margin-left: 20px;">导出</el-button>
</div>
    </div>
    <!-- 表格 -->
    <div class="wrpper">
      <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      fixed
      prop="type"
      label="类型"
      width="150">
    </el-table-column>
    <el-table-column
      prop="name"
      label="名称"
      width="120">
    </el-table-column>
    <el-table-column
      prop="pictureUrl"
      label="图片"
      width="120">
        <template   slot-scope="scope">            
          <img :src="scope.row.pictureUrl"  min-width="70" height="70" />
        </template> 
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="300">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          <el-button
          size="mini"
          type="danger"
          @click="handTo(scope.$index, scope.row)">
          三级分类</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>
    <!-- 分页 -->
    <div>
      <MyPagination :total="total" :page-size="pageSize" @changePage="change" />
    </div>
     <!-- 弹框 --> 
    <div>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"  
        :before-close="clearForm"
        width="70%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="类型" prop="type">
          <el-input v-model="ruleForm.type"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商品图片" prop="pictureUrl">
          <el-button type="primary" @click="uploadimg" :disabled="isDisable">上传图片</el-button>
          <el-popover
            placement="top"
            width="160"
            rigger="hover">
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="handleImgClick">查看</el-button>
              <el-button type="primary" size="mini" @click="imgdelete">删除</el-button>
              <el-button type="primary" size="mini" @click="visible = false">取消</el-button>
            </div>
            <img slot="reference" :src="this.ruleForm.pictureUrl" width="100px"/>
          </el-popover>
          
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
        <!-- 内弹框 图片 -->
        <el-dialog
        width="40%"
        title="图片上传"
        :visible.sync="innerVisibleimg"
        append-to-body
        >  
        <Uploadimg @sendImg="sendImg" ref="upload" :limit="limit"/>
        <span slot="footer" class="dialog-footer">
          <el-button @click="offImg">取消</el-button>
          <el-button @click="showImg">确认</el-button>
        </span>
        </el-dialog>
      </el-dialog>
    </div>
  </div>
 
  
</template>
<script>
import Uploadimg from '../kind/UpLoad-img.vue'
import MyPagination from '@/components/MyPagination.vue'
import base from '../api/base';
export default {
  components:{
    MyPagination,
    Uploadimg
  },
  data() {
    return {
      fileList:'',
      imurl:base.import,
      exurl:base.export,
      token:'',
      imdata:{},
      exdata:{parentId:''},
      limit: 1,
      imageUrl:'',
      visible: false,
      isDisable:false,
      index:'',
      title:"添加商品",
      rowdata:{
        type:Object,
        default:function(){
          return{}
        }
      },
      res:'',
      tableData: [],
      data:[],
      input: '',
      total:1,  
      pageSize:1,
      page:'1',
      val:'',
      xsimg:'',
      dedaoimg:'',
      parentId:'',
      dialogVisible: false,
      innerVisibleimg:false,
      ruleForm: {
        type:'',
        name:'',
        pictureUrl:''
      },
      rules: {
          type:[{
            required: true, message: '请输入类型', trigger: 'blur'}
          ],
          name:[{
            required: true, message: '请输入名称', trigger: 'blur'}
          ],
        }
    }
  },
  // 监听器 
  watch:{
    rowdata(){
      
    }
  },
  props:{
  },
  methods:{
    imsuccess(){
        this.http(1)
        this.$message.error('导入成功',)
      },
   imerror(error){
    this.$message.error('导入失败',error)
    if(error.response) {
         
         // 请求成功，但服务器响应错误
         if(error.response.status==401){this.$router.push({name:'Login'})}
       }

   },
     handleRemove() {
   
      },
      handlePreview() {
       
      },
      handleExceed() {
       
      },
      beforeRemove() {
        
      },
    // 导出
    exportdata(){ 
      this.$api.export({parentId:this.parentId})
      .then(res=>{
        const fileName=decodeURI(          
           res.headers["content-disposition"].split("=")[1]
        );
        console.log(fileName)
      let url = window.URL.createObjectURL(new Blob([res.data], { type: '.xlsx' }));
      let a= document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.setAttribute('download', fileName);
       document.body.appendChild(a);
       a.click();
       url = window.URL.revokeObjectURL(url);
       document.body.removeChild(a)
       this.http(1)
       this.$message.success('导出成功',)
}).catch(error =>{
  this.$message.error('导出失败',error)
       if(error.response) {
         
     // 请求成功，但服务器响应错误
     if(error.response.status==401){this.$router.push({name:'Login'})}
   } else if(error.request) {
     // 请求已发送但没有收到响应
     console.log(error.request);
   } else
{
     // 其他错误
console.log('Error', error.message);
   }
 });


    },
    // 返回
    goBack() {
      this.$router.push({ name:'first', query:{name:"first" } })
  
},
    changePage(num){
      this.http(num)
    },
    // 预览
    handleImgClick() {
      const image = new Image()
      this.imageUrl= this.ruleForm.pictureUrl
      image.src = this.ruleForm.pictureUrl
      image.onload = () => {
        //创建弹出层
        const previewContatiner = document.createElement('div')
        previewContatiner.style.position = 'fixed'
        previewContatiner.style.top = 0
        previewContatiner.style.bottom = 0
        previewContatiner.style.left = 0
        previewContatiner.style.right = 0
        previewContatiner.style.zIndex = 9999
        previewContatiner.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContatiner.style.display = 'flex'
        previewContatiner.style.justifyContent = 'center'
        previewContatiner.style.alignItems = 'center'
        document.body.appendChild(previewContatiner)
        //在弹出层增加图片
        const previewImage = document.createElement('img')
        previewImage.src = this.imageUrl
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewImage.style.zIndex = 9999
        previewContatiner.appendChild(previewImage)
        //点击弹出层，关闭预览
        previewContatiner.addEventListener('click', () => {
          document.body.removeChild(previewContatiner)
        })
      }
      image.onerror = function () {
        console.log('图片加载失败')
      }
    },
    // 搜索
    searchInp(val){
      this.val=val
    },
    search(){
      var val =this.val
      var page=this.page
      var id = this.$route.query.id
      if(val!=null){
      this.$api.postGoodsLists({parentId:id,page:page,size:5,name:val})
      .then(res=>{
        
        this.total=res.data.data.total
        this.tableData= res.data.data.records;      
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else
 {
      // 其他错误
console.log('Error', error.message);
    }
  })
    }else{
      this.tableData=this.data
    }
    },
    //重置
    reset(){
      this.tableData=this.data
      this.total=this.res.total
    },
    //删除
    handleDelete(index,row){
      var id= row.id
      this.$confirm('此操作将永久删除该类别, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.deletelb({
            id:id
          }).then(res=>{
            console.log(res)
            if(res.status==200){
             this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.http(1)

            }     
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else
 {
      // 其他错误
console.log('Error', error.message);
    }
  })  
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      
    }, 
    // 删除img
    imgdelete(){
      this.ruleForm.pictureUrl=''
      this.visible = false
    },
    //编辑
    handleEdit(index,row){
      this.index = index;
      this.dialogVisible=true;
      this.title='编辑商品'
      this.rowdata={...row}
      this.ruleForm=row
      if(this.ruleForm.pictureUrl==null){
        this.isDisable=false
      }else{
        this.isDisable=true
      }
     
      
    },
    //弹框
    add(){
      this.dialogVisible=true
      this.title='添加商品'
    },
    // 添加&编辑
    submitForm() {
      if(this.data!=null){
        this.ruleForm.pictureUrl=this.dedaoimg;
      }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
           var name =this.ruleForm.name
           var pictureUrl =this.ruleForm.pictureUrl
           var type =this.ruleForm.type
           var id = this.parentId
           if(this.title==="添加商品"){
            this.$api.addfrist({parentId: id, name:name,pictureUrl:pictureUrl,type:type})
      .then(res=>{
        if(res.status==200){
          this.http(1)
          this.dialogVisible=false
          this.$message({
            message:'添加成功',
            type:'success'
          });
          this.ruleForm={
        type:'',
        name:'',
        pictureUrl:''
      },
      this.dedaoimg='';
        }else{
          this.$message.error('添加失败')
        }
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
      if(error.response.status==401){this.$router.push({name:'Login'})}
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else{
      // 其他错误
console.log('Error', error.message);
    }
  });
    }else{
           var bjid =this.ruleForm.id
           var bjname =this.ruleForm.name
           var bjpictureUrl =this.ruleForm.pictureUrl
           var bjtype =this.ruleForm.type
      this.$api.updatalb({id:bjid,name:bjname,pictureUrl:bjpictureUrl,type:bjtype}).then(res=>{
        if(res.status==200){
          this.dialogVisible=false
          this.http(1)
          this.dialogVisible=false
          this.$message({
            message:'编辑成功',
            type:'success'
          });
          this.ruleForm={
        type:'',
        name:'',
        pictureUrl:''
      },
          this.img='';
        }else{
          this.$message.error('编辑失败')
        }
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else{
      // 其他错误
console.log('Error', error.message);
    }
  });
            console.log("编辑商品")
           }
           
          }
        });
      this.dialogVisible = false
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    // 清空表单
    clearForm(){
      this.dialogVisible=false
      // this.$refs.ruleForm.resetFields()
      this.ruleForm={
        type:'',
        name:'',
        pictureUrl:''
      }
       
    },
    offImg(){
      this.ruleForm={
        type:'',
        name:'',
        pictureUrl:''
      }
      
    }, 
   //上传图片
   uploadimg(){
      this.innerVisibleimg=true;
      this.$refs.upload.clearFiles()
      
    }, 
    //显示网址图片
    sendImg(dedaoimg,xsimg){
      this.dedaoimg=dedaoimg
      this.xsimg=xsimg
      this.ruleForm.pictureUrl=xsimg

    },
    showImg(){
      this.innerVisibleimg = false;
      this.$refs.upload.submitUpload()
    
    },
    //跳转 
    handTo(index,row){
      var parentId=row.id
      this.$router.push({ name:'three', query:{id1to2:this.parentId,id2to3:parentId} })
    },
    change(num){
      if(this.val==null){
      this.http(num)
    }else{
      this.page=num
      this.search()
    }
    }, 
    http(page){
          this.parentId=this.$route.query.id1to2
           this.imdata={"parentId":this.$route.query.id1to2}

          this.$api.postGoodsList({parentId:this.parentId,page:page,size:5})
        .then(res=>{
          this.tableData= res.data.data.records;
          this.data=res.data.data.records;
          this.total=res.data.data.total;
          this.pageSize=res.data.data.size;
          this.res=res.data.data
        }).catch(error =>{
          if(error.response) {
        // 请求成功，但服务器响应错误
        if(error.response.status==401){this.$router.push({name:'Login'})}
        console.log(error.response.data);
        } else if(error.request) {
        // 请求已发送但没有收到响应
        console.log(error.request);
        } else{
        // 其他错误
          console.log('Error', error.message);
          }
           });
        
          }   
  },
  created:function (){
    this.token=localStorage.getItem('token');
    this.http(1)
    
      

  
    },

  
}
</script>
<style lang='less' scoped>
.goods{
  margin: 20px;
}
.title{
  padding: 10px;
  background: #fff;
  border: 1px solid #eee;
  margin-bottom: 10px;
}
.header{
  display: flex;
  input{
    width: 800px;
  }
  bottom{
    margin-left: 20px;
   }
}
.wrpper{
  margin: 20px 0;

}
</style>
