<template>
  <div class="connect">
    <div class="top1">
      <svg v-if='!isCollapse' @click="changeMenU" t="1725764362411" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4290" width="20" height="20"><path d="M0 73.142857A36.571429 36.571429 0 0 1 36.571429 36.571429h950.857142a36.571429 36.571429 0 0 1 0 73.142857H36.571429A36.571429 36.571429 0 0 1 0 73.142857zM0 292.571429a36.571429 36.571429 0 0 1 36.571429-36.571429h731.428571a36.571429 36.571429 0 0 1 0 73.142857H36.571429A36.571429 36.571429 0 0 1 0 292.571429zM0 512a36.571429 36.571429 0 0 1 36.571429-36.571429h512a36.571429 36.571429 0 0 1 0 73.142858h-512A36.571429 36.571429 0 0 1 0 512zM0 950.857143a36.571429 36.571429 0 0 1 36.571429-36.571429h950.857142a36.571429 36.571429 0 0 1 0 73.142857H36.571429A36.571429 36.571429 0 0 1 0 950.857143zM0 731.428571a36.571429 36.571429 0 0 1 36.571429-36.571428h731.428571a36.571429 36.571429 0 0 1 0 73.142857H36.571429A36.571429 36.571429 0 0 1 0 731.428571z" fill="#000000" p-id="4291"></path></svg>
      <svg v-if='isCollapse' @click="changeMenU" t="1725764383981" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4443" width="20" height="20"><path d="M0 73.142857A36.571429 36.571429 0 0 1 36.571429 36.571429h950.857142a36.571429 36.571429 0 0 1 0 73.142857H36.571429A36.571429 36.571429 0 0 1 0 73.142857zM219.428571 292.571429a36.571429 36.571429 0 0 1 36.571429-36.571429h731.428571a36.571429 36.571429 0 0 1 0 73.142857h-731.428571A36.571429 36.571429 0 0 1 219.428571 292.571429zM438.857143 512a36.571429 36.571429 0 0 1 36.571428-36.571429h512a36.571429 36.571429 0 0 1 0 73.142858h-512A36.571429 36.571429 0 0 1 438.857143 512zM0 950.857143a36.571429 36.571429 0 0 1 36.571429-36.571429h950.857142a36.571429 36.571429 0 0 1 0 73.142857H36.571429A36.571429 36.571429 0 0 1 0 950.857143zM219.428571 731.428571a36.571429 36.571429 0 0 1 36.571429-36.571428h731.428571a36.571429 36.571429 0 0 1 0 73.142857h-731.428571A36.571429 36.571429 0 0 1 219.428571 731.428571z" fill="#000000" p-id="4444"></path></svg>
    </div>
    <div>
      <router-view/>
    </div>
  </div>
</template>
<script>

export default {  
  props:['isCollapse'], 

  methods:{
    changeMenU(){
      this.$emit('change')
      
    }
 
  }
}

</script>

<style lang='less' scoped>
.top1{
    width: 100%;
    height: 50px;
    background-color: aqua;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
