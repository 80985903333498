import axios from "axios"
import base from "./base"
var token=localStorage.getItem('token')
 axios.defaults.headers.common['Token'] = token;

 





const api ={
    gettoken(p){
        return axios.post(base.gettoken,{"username":p.username,"password":p.password},)},
    export(p){
        return axios.post(base.export,{"parentId":p.parentId},
        {headers:{ 'Content-Type': "application/json;charset=UTF-8"},responseType:"arraybuffer"
    })},
    postGoodsList(p){
        return axios.post(base.goodslist,{"parentId":p.parentId,"page":p.page,"size":p.size,   
        })
    },
    postGoodsLists(p){
        return axios.post(base.goodslist,{"parentId":p.parentId,"page":p.page,"size":p.size,"name":p.name
            
        })
    },
    postGoodsListt(p){
        return axios.post(base.goodslist,{
            "parentId":p.parentId,"page":p.page,"size":p.size
            
        })
    },
    postGoodsListg(p){
        return axios.post(base.goodsquery,{
            "lampId":p.parentId,
            // "lampId":3
        })
    },
    downfile(p){
        return axios.post(base.downimg,{
            pictureUrl:p.filePath
        })
    },
    addfrist(p){
        return axios.post(base.addfirst,{
        "name": p.name,
        "parentId": p.parentId,
        "pictureUrl": p.pictureUrl,
        "type": p.type
            
        })
    },
    addlamp(p){
        return axios.post(base.addlamp,{
            "lampId":p.parentId,
            "type":p.type,
            "modelNumber":p.modelNumber,
            "brand":p.brand,
            "price":p.price,
            "specs":p.specs,
            "colour":p.colour,
            "lightSource":p.lightSource,
            "power":p.power,
            "controlMode":p.controlMode,
            "material":p.material,
            "irradiationArea":p.irradiationArea,
            "applicableSpace":p.applicableSpace,
            "warrantyPeriod":p.warrantyPeriod,

        })
    },
    uplamp(p){
        return axios.post(base.uplamp,{
            "id":p.id,
            "lampId":p.parentId,
            "type":p.type,
            "modelNumber":p.modelNumber,
            "brand":p.brand,
            "price":p.price,
            "specs":p.specs,
            "colour":p.colour,
            "lightSource":p.lightSource,
            "power":p.power,
            "controlMode":p.controlMode,
            "material":p.material,
            "irradiationArea":p.irradiationArea,
            "applicableSpace":p.applicableSpace,
            "warrantyPeriod":p.warrantyPeriod,

        })
    },
    
    deletelb(p){
        return axios.post(base.deletelb,{
            id:p.id
        })
    },
    deletelamp(p){
        return axios.post(base.deletelamp,{
            id:p.id
        })
    },
    updatalb(p){
        return axios.post(base.updatalb,{
        "id": p.id,"name": p.name,parentId: 0,"pictureUrl": p.pictureUrl,"type": p.type
        })
    },
    addimg(p){
        return axios.post(base.addimg,{
            "lampId": p.lampId,
            "pictureUrl":p.pictureUrl

        })
    },
    deleteimg(p){
        return axios.post(base.deleteimg,{
           "id":p.id
        })
    }

}
export default api