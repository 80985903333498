<template>
    <div class="addgoods">
      <div class="title">
        <el-page-header @back="goBack" content="详情页面">
        </el-page-header>
      </div>
      <!-- 表单 -->
      <div  class="myfrom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="类型" prop="type">
            <el-input v-model="ruleForm.type"></el-input>
          </el-form-item>
          <el-form-item label="品牌" prop="brand">
            <el-input v-model="ruleForm.brand"></el-input>
          </el-form-item>
          <el-form-item label="型号" prop="modelNumber">
            <el-input v-model="ruleForm.modelNumber"></el-input>
          </el-form-item>
          <el-form-item label="价格" prop="price">
            <el-input v-model="ruleForm.price"></el-input>
          </el-form-item>
          <el-form-item label="规格" prop="specs">
            <el-input v-model="ruleForm.specs"></el-input>
          </el-form-item>
          <el-form-item label="颜色" prop="colour">
            <el-input v-model="ruleForm.colour"></el-input>
          </el-form-item>
          <el-form-item label="光源" prop="lightSource">
            <el-input v-model="ruleForm.lightSource"></el-input>
          </el-form-item>
          <el-form-item label="功率" prop="power">
            <el-input v-model="ruleForm.power"></el-input>
          </el-form-item>
          <el-form-item label="控制方式" prop="controlMode">
            <el-input v-model="ruleForm.controlMode"></el-input>
          </el-form-item>
          <el-form-item label="主体材质" prop="material">
            <el-input v-model="ruleForm.material"></el-input>
          </el-form-item>
          <el-form-item label="照射面积" prop="irradiationArea">
            <el-input v-model="ruleForm.irradiationArea"></el-input>
          </el-form-item>
          <el-form-item label="适用空间" prop="pplicableSpace">
            <el-input v-model="ruleForm.applicableSpace"></el-input>
          </el-form-item>
          <el-form-item label="质保年限" prop="warrantyPeriod">
            <el-input v-model="ruleForm.warrantyPeriod"></el-input>
          </el-form-item>
          <el-form-item label="商品图片" prop="pictureUrl"  >
            <div class="img">
            <div>
            <el-button type="primary" @click="uploadimg" width="100px" >上传图片</el-button>
            </div>
            <div v-for="(item,index) in addnxsimg" :key="index" class="nxsimg">
            <el-popover
              placement="top"
              width="160"
              trigger="hover"
              >
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="handleImgClick( item,index)">查看</el-button>
                <el-button type="primary" size="mini" @click="imgdelete(item,index)">删除</el-button>
                <el-button type="primary" size="mini" @click="visible = false">取消</el-button>
              </div>
              <img slot="reference" :src="item.pictureUrl" width="100px"  @click="visible = true"/>
            </el-popover>
          </div>
        </div>
          </el-form-item>
            <!-- <el-form-item label="商品视频" prop="pictureUrl"  >
            <div class="img">
            <div>
            <el-button type="primary" @click="uploadimg" width="100px" >上传视频</el-button>
            </div>
            <div v-for="(item,index) in addnxvedio" :key="index" class="nxsimg">
            <el-popover
              placement="top"
              width="160"
              trigger="hover"
              >
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="handleImgClick( item,index)">查看</el-button>
                <el-button type="primary" size="mini" @click="imgdelete(item,index)">删除</el-button>
                <el-button type="primary" size="mini" @click="visible = false">取消</el-button>
              </div>
              <img slot="reference" :src="item.pictureUrl" width="100px"  @click="visible = true"/>
             
            </el-popover>
          </div>
        </div>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="submitForm">编辑</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
  </el-form>
      </div>
      <!-- 上传图片 -->
      <el-dialog
          width="40%"
          title="图片上传"
          :visible.sync="innerVisibleimg"
          append-to-body> 
          <UpLoadImg @sendImgid="sendImgid" ref="upload"  :limit="this.limit" :parentId="this.parentId"/>
          <span slot="footer" class="dialog-footer">
            <el-button @click="offImg">取消</el-button>
            <el-button @click="showImg">确认</el-button>
          </span>
          </el-dialog>
  </div>
  </template>
  <script>
  import UpLoadImg from '../kind/UpLoad-img.vue';
  import Uploadimg from '../kind/UpLoad-img.vue'
  export default {
      comments: {
          Uploadimg
      },
      data() {
          return {
            arr:[1],
            lampPics:[],
              limit:2,
              nxsimgUrl:'',
              visible : false,
              innerVisibleimg: false,
              xsimg:'',
              addnxsimg:'',
              dedaoimg:'',
              imageUrl:'',
              parentId:'',
              add:true,
              id1to2:'',
              id2to3:'',
              id3to4:'',
              ruleForm: {
                  delivery: false,
                  type: '',
                  modelNumber: '',
                  price:'',
                  brand: '',
                  specs: '',
                  colour: '',
                  lightSource: '',
                  power: '',
                  controlMode:'',
                  material: '',
                  irradiationArea: '',
                  applicableSpace:'',
                  warrantyPeriod: '',
                  pictureUrl: ''
              },
              rules: {
                  type: [{
                          required: true, message: '请输入类型', trigger: 'blur'
                      }
                  ],
                  modelNumber: [{
                          required: true, message: '请输入名称', trigger: 'blur'
                      }
                  ],
                 
              }
          };
      },
      created:function(){
        
        this.http()
        
      },
      methods: {
         // 预览
    handleImgClick(index ) {
        const image = new Image()
        var img=this.nxsimg[index]
        this.imageUrl= img
        image.src = img
        image.onload = () => {
          //创建弹出层
          const previewContatiner = document.createElement('div')
          previewContatiner.style.position = 'fixed'
          previewContatiner.style.top = 0
          previewContatiner.style.bottom = 0
          previewContatiner.style.left = 0
          previewContatiner.style.right = 0
          previewContatiner.style.zIndex = 9999
          previewContatiner.style.backgroundColor = 'rgba(0,0,0,0.8)'
          previewContatiner.style.display = 'flex'
          previewContatiner.style.justifyContent = 'center'
          previewContatiner.style.alignItems = 'center'
          document.body.appendChild(previewContatiner)
          //在弹出层增加图片
          const previewImage = document.createElement('img')
          previewImage.src = img
          previewImage.style.maxWidth = '80%'
          previewImage.style.maxHeight = '80%'
          previewImage.style.zIndex = 9999
          previewContatiner.appendChild(previewImage)
          //点击弹出层，关闭预览
          previewContatiner.addEventListener('click', () => {
            document.body.removeChild(previewContatiner)
          })
        }
        image.onerror = function () {
          console.log('图片加载失败')
        }
      },
      
        http(){
          this.id1to2=this.$route.query.id1to2
          this.id2to3=this.$route.query.id2to3
          this.id3to4=this.$route.query.id3to4
          this.parentId=this.$route.query.id3to4
          var title=this.$route.query.title
          if(title=="编辑商品"){
          this.add=false
          this.ruleForm=this.$route.query.res
          var lampPics=this.ruleForm.lampPics
          var img= lampPics.filter(function(lampPics){
                  return lampPics.pictureType==1
                 });
          
          this.addnxsimg=img
        }
      },
  
      // 删除img
      imgdelete(item,index){
        var id=item.id
        // var pictureUrl=this.nxsimg[index]
        this.addnxsimg.splice(index,1)
        this.$api.deleteimg({
          "id":id,
          }).then(res=>{
          if(res==null){
            return
        }
        }).catch(error =>{if(error.response) {
        // 请求成功，但服务器响应错误
        console.log(error.response.data);
      } else if(error.request) {
        // 请求已发送但没有收到响应
        console.log(error.request);
      } else{
        // 其他错误
  console.log('Error', error.message);
      }
    });
      },
          // 返回
          goBack() {
              this.$router.push({ name: 'goods',query:{id1to2:this.id1to2,id2to3:this.id2to3,id3to4:this.id3to4,} });
              this.add=true
              this.resetForm= {
                  delivery: false,
                  type: '',
                  modelNumber: '',
                  price:'',
                  brand: '',
                  specs: '',
                  colour: '',
                  lightSource: '',
                  power: '',
                  controlMode:'',
                  material: '',
                  irradiationArea: '',
                  applicableSpace: '',
                  warrantyPeriod: '',
                  pictureUrl: ''
              }
              this.nxsimg=''
          },
          
          //得到网址图片
          sendImgid(addimg){
            this.addnxsimg.push(addimg)
            console.log(addimg)
          },
          // 确认
          showImg(){
              this.innerVisibleimg = false;
              this.$refs.upload.submitUpload(1)

              
              
          },
        //   取消
          offImg() {
              this.innerVisibleimg = false; 
          },
          uploadimg(){
            this.innerVisibleimg=true;
            this.$refs.upload.clearFiles()
    },
          // 创建编辑
          submitForm() {
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                var parentId=this.parentId
                var type=this.ruleForm.type
                var modelNumber=this.ruleForm.modelNumber
                var price=this.ruleForm.price
                var brand=this.ruleForm.brand
                var specs=this.ruleForm.specs
                var colour=this.ruleForm.colour
                var lightSource=this.ruleForm.lightSource
                var power=this.ruleForm.power
                var controlMode =this.ruleForm.controlMode
                var material=this.ruleForm.material
                var irradiationArea=this.ruleForm.irradiationArea
                var applicableSpace=this.ruleForm.applicableSpace
                var warrantyPeriod=this.ruleForm.warrantyPeriod
                var add=this.add
                if(add){
                // 添加
                console.log(this.add)
                console.log(parentId)
                this.$api.addlamp({
                parentId:parentId,type:type,modelNumber:modelNumber,price:price,brand:brand,specs:specs,colour:colour,
                lightSource:lightSource, power:power,controlMode:controlMode,material:material,irradiationArea:irradiationArea,applicableSpace:applicableSpace,
                warrantyPeriod:warrantyPeriod,
              }).then(res=>{
          if(res.status==200){
            
            this.$message({
              message:'添加成功',
              type:'success'
            });
          }
          }).catch(error =>{
          if(error.response) {
        // 请求成功，但服务器响应错误
        console.log(error.response.data);
      } else if(error.request) {
        // 请求已发送但没有收到响应
        console.log(error.request);
      } else{
        // 其他错误
  console.log('Error', error.message);
      }
    });
              }else{
                // 编辑
                console.log("编辑")
                console.log(this.add)
                var id =this.ruleForm.id
                this.$api.uplamp({
                id:id,parentId:parentId,type:type,modelNumber:modelNumber,price:price,brand:brand,specs:specs,colour:colour,
                lightSource:lightSource, power:power,controlMode:controlMode,material:material,irradiationArea:irradiationArea,applicableSpace:applicableSpace,
                warrantyPeriod:warrantyPeriod,
              }).then(res=>{
          if(res.status==200){
            this.goBack()
            this.$message({
              message:'编辑成功',
              type:'success'
            });
            this.ruleForm={}
          }else{
            this.$message.error('编辑失败')
          }
        }).catch(error =>{
          if(error.response) {
        // 请求成功，但服务器响应错误
        console.log(error.response.data);
      } else if(error.request) {
        // 请求已发送但没有收到响应
        console.log(error.request);
      } else{
        // 其他错误
  console.log('Error', error.message);
      }
    });
              }
              } else {
              console.log('error submit!!');
              return false;
            }
          });
  
        },
        //重置
          resetForm() {
            this.ruleForm= {
                  delivery: false,
                  type: '',
                  modelNumber: '',
                  price:'',
                  brand: '',
                  specs: '',
                  colour: '',
                  lightSource: '',
                  power: '',
                  controlMode:'',
                  material: '',
                  irradiationArea: '',
                  applicableSpace: '',
                  warrantyPeriod: '',
                  pictureUrl: ''
              }
              this.nxsimg=''
            
          }
  
      },
      components: { UpLoadImg }
  
    }
  
  
  </script>
  
  <style lang='less' scoped>
  .addgoods{
    margin: 10px;
  }
  .title{
    padding: 10px;
    background: #fff;
    border: 1px solid #eee;
    margin-bottom: 10px;
  }
  .myfrom{
    background: #fff;
    border: 1px solid #eee;
    padding: 10px;
    
  }
  .img{
      display: flex;
      flex-direction: row;
      flex-wrap:wrap;
  }
  .nxsimg{
    width: 100px;
   
    margin-left: 10px;
  }
  
  </style>
  