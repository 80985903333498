<template>
    <div class="goods">
      <!-- 搜索 -->
      <div class="title">
      <el-page-header @back="goBack" content="商品详情">
      </el-page-header>
      </div>
      <div >
        <el-input  @change="searchInp" style="width: 800px;" v-model="input" placeholder="请输入内容"></el-input>
        <el-button @click="search" type="primary" :disabled="true" >搜索</el-button>
        <el-button @click="reset" type="primary" >重置</el-button>
        <el-button type="primary" @click="add" :disabled="isDisable" >
          新建
        </el-button>
        <div class="header">
      <el-upload 
      :action="imurl" 
      :data="imdata"
      :headers="{Token:token}"
      :on-success="imsuccess"
      :on-error="imerror"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      multiple
      :on-exceed="handleExceed"
      >
  <el-button  type="primary">导入</el-button>
</el-upload>
<el-button  type="primary" @click="exportdata" style="margin-left: 20px;">导出</el-button>
</div>
      </div>
      <!-- 表格 -->
      <div class="wrpper">
        <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        fixed
        prop="type"
        label="类型"
        width="150">
      </el-table-column>
      <el-table-column
        prop="brand"
        label="品牌"
        width="120">
      </el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="120">
      </el-table-column>
      <el-table-column
        prop="modelNumber"
        label="型号"
        width="120">
      </el-table-column>
      <el-table-column
        prop="specs"
        label="规格"
        width="120">
      </el-table-column>
      <el-table-column
        prop="colour"
        label="颜色"
        width="120">
      </el-table-column>
      <el-table-column
        prop="lightSource"
        label="光源"
        width="120">
      </el-table-column>
      <el-table-column
        prop="power"
        label="功率"
        width="120">
      </el-table-column>
      <el-table-column
        prop="controlMode"
        label="控制方式"
        width="120">
      </el-table-column>
      <el-table-column
        prop="material"
        label="主体材质"
        width="120">
      </el-table-column>
      <el-table-column
        prop="irradiationArea"
        label="照射面积"
        width="120">
      </el-table-column>
      <el-table-column
        prop="applicableSpace"
        label="适用空间"
        width="120">
      </el-table-column>
      <el-table-column
        prop="warrantyPeriod"
        label="质保年限"
        width="120">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="150">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
      </div>
      <!-- 分页 -->
      
    </div>
    
  </template>
  <script>
  
  
  import MyPagination from '../components/MyPagination.vue';
  import base from '../api/base';
  
  
  export default {
    data() {
      return {
        imurl:base.import,
      exurl:base.export,
      imdata:{parentId:0},
      exdata:{parentId:0},
      token:'',
        tableData:null,
        res:'',
        input: '',
        total:1,
        pageSize:1,
        id1to2:'',
        id2to3:'',
        parentId:'',
        isDisable:false
        
      }
    },
    props:{
  
    },
    components:{
      MyPagination,
      
  },
    methods:{
      // 导入
      imsuccess(){
        this.http(1)
        this.$message.success('导入成功',)
      },
   imerror(error){
    this.$message.error('导入失败',error)
    if(error.response) {
         
         // 请求成功，但服务器响应错误
         if(error.response.status==401){this.$router.push({name:'Login'})}
       }

   },
    handleRemove() {
        
      },
      handlePreview() {
       
      },
      handleExceed() {
      },
      beforeRemove() {
        
      },
    // 导出
    exportdata(){ 
      this.$api.export({parentId:0})
      .then((res)=>{
        
        const fileName=decodeURI(          
           res.headers["content-disposition"].split("=")[1]
        );
        console.log(fileName)
      let url = window.URL.createObjectURL(new Blob([res.data], { type: '.xlsx' }));
      let a= document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.setAttribute('download', fileName);
       document.body.appendChild(a);
       a.click();
       url = window.URL.revokeObjectURL(url);
       document.body.removeChild(a)
       this.http(1)
       this.$message.success('导出成功',)
}).catch(error =>{
  this.$message.error('导出失败',error)
       if(error.response) {
         
     // 请求成功，但服务器响应错误
     if(error.response.status==401){this.$router.push({name:'Login'})}
   } else if(error.request) {
     // 请求已发送但没有收到响应
     console.log(error.request);
   } else
{
     // 其他错误
console.log('Error', error.message);
   }
 });
},


      // 返回
      goBack() {
  this.$router.push({ name:'three', query:{id3to4:this.parentId,id1to2:this.id1to2,id2to3:this.id2to3 }  })
  
},

      changePage(num){
        this.http(num)
      },
      // 搜索
      searchInp(){
        
      },
      search(){
        
      },
      //重置
      reset(){
        
      },
      // 新建
      add(){
        this.$router.push({ name:'addgoods',query:{id1to2:this.id1to2,id2to3:this.id2to3,id3to4:this.parentId,}})
        

      },
      //删除
      handleDelete(index,row){
      var id= row.id
      this.$confirm('此操作将永久删除该类别, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.deletelamp({
            id:id
          }).then(res=>{
            this.http();
            
            if(res.status==200){
              
             this.$message({
            type: 'success',
            message: '删除成功!'
          });
         

            }     
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else
 {
      // 其他错误
console.log('Error', error.message);
    }
  })  
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      
    }, 
    
      //编辑
      handleEdit(index,row){
      var res=row
        this.$router.push({ name:'enditgoods',query:{id1to2:this.id1to2,id2to3:this.id2to3,id3to4:this.parentId,res:res,title:"编辑商品"}})
      },
      // 商品列表获取
      http(){
      this.id1to2=this.$route.query.id1to2
      this.id2to3=this.$route.query.id2to3
      this.parentId=this.$route.query.id3to4
      var parentId=this.$route.query.id3to4
        this.$api.postGoodsListg({parentId:parentId})
      .then(res=>{
        console.log(res)
        if(res.data.data==null){
          this.isDisable=false
         this.ruleForm= {
                delivery: false,
                type: '',
                modelNumber: '',
                price:'',
                brand: '',
                specs: '',
                colour: '',
                lightSource: '',
                power: '',
                material: '',
                irradiationArea: '',
                applicableSpace: '',
                warrantyPeriod: '',
                pictureUrl: ''
            }
        }else{
          this. isDisable=true
          this.tableData= [res.data.data];}
      }).catch(error =>{
        if(error.response) {
      // 请求成功，但服务器响应错误
      console.log(error.response.data);
      
      if(error.response.status==401){this.$router.push({name:'Login'})}
    } else if(error.request) {
      // 请求已发送但没有收到响应
      console.log(error.request);
    } else
 {
      // 其他错误
console.log('Error', error.message);
    }
  });
       
    }

      
    },
    created:function (){
      this.token=localStorage.getItem('token');
      this.http();
  
    
      },
  
    
  }
  </script>
  <style lang='less' scoped>
  .goods{
    margin: 20px;
  }
  .header{
    display: flex;
    input{
      width: 800px;
    }
    bottom{
      margin-left: 20px;
     }
  }
  .title{
  padding: 10px;
  background: #fff;
  border: 1px solid #eee;
  margin-bottom: 10px;
}
  .wrpper{
    margin: 20px 0;
  
  }
  </style>
  