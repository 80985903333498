const base={
    goodslist:'/api/lamp/page',
    goodsquery: '/api/lampDetail/query',
    addfirst:'/api/lamp/add',
    addlamp:'/api/lampDetail/add',
    uplamp:'/api/lampDetail/update',
    deletelamp:'/api/lampDetail/delete',
    Upload:'/api/file/upload',
    downimg:'/api/file/download',
    deletelb:'/api/lamp/delete',
    updatalb:'/api/lamp/update',
    addimg:'/api/lampPics/add',
    deleteimg:'/api/lampPics/delete',
    gettoken:'/api/lamp/getToken',
    import:'/api/lamp/import',
    export:'/api/lamp/export'


}
export default base;
